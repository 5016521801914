import { getCurrentInstance, reactive, UnwrapRef } from "@vue/composition-api";
import VueRouter, { RouteMeta, RouteRecord } from "vue-router";
import { Dictionary } from "vue-router/types/router";

type RouterObj = UnwrapRef<{
  fullPath: string;
  path: string;
  meta?: RouteMeta;
  query: Dictionary<string | (string | null)[]>;
  name?: string | null;
  matched: RouteRecord[];
  redirectedFrom?: string;
  params: Dictionary<string>;
  hash: string;
}>;
let currentRoute: RouterObj;

//хуки useRouter и useRoute взяты отсюда: https://github.com/ambit-tsai/vue2-helpers/blob/fb6635de90/src/vue-router.ts
export function useRouter(): VueRouter | never {
  const inst = getCurrentInstance();
  if (!inst) throw new Error("Отсутсвует экземпляр компонента или роутера");
  return inst.proxy.$router;
}

export function useRoute(): RouterObj {
  const router = useRouter();
  if (!currentRoute) {
    const inst = getCurrentInstance();
    if (!inst || !router) {
      console.error("Отсутсвует экземпляр компонента или роутера");
      throw new Error("Отсутсвует экземпляр компонента или роутера");
    }
    currentRoute = reactive({ ...inst.proxy.$route });
    router.afterEach((to) => Object.assign(currentRoute, to));
  }
  return currentRoute;
}
