

















import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "IconThinArrowBottom",
  props: {
    width: {
      type: Number as PropType<number>,
      default: () => 8,
    },
    height: {
      type: Number as PropType<number>,
      default: () => 4,
    },
    fill: {
      type: String as PropType<string>,
      default: () => "currentColor",
    },
  },
});
