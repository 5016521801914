















































































































import { computed, defineComponent, PropType, ref } from "@vue/composition-api";

import IconClose from "@/app/components/Icons/IconClose.vue";
import { ListItem, Option, Value } from "@/shared/types/components/Base/VSelect";


export default defineComponent({
  name: "VSelect",
  components: { IconClose },
  props: {
    value: {
      type: String as PropType<Value>,
      default: () => null,
    },
    isSearchable: {
      type: Boolean,
      default: () => false,
    },
    isClearable: {
      type: Boolean,
      default: () => true,
    },
    returnObject: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      default: () => '',
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    maxWidth: {
      type: String,
      default: () => '100%',
    },
    items: {
      type: Array as PropType<Option[]>,
      default: () => [],
    },
    textValue: {
      type: String,
      default: () => 'value',
    },
    idValue: {
      type: String,
      default: () => 'id',
    },
    noDataItems: {
      type: String,
      default: () =>'Список пустой',
    },
    selectAll: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    selectFirstItem: {
      type: Boolean,
      default: () => false,
    },
    dropdownClass: {
      type: String,
      default: () => null,
    },
  },
  setup(props, { emit, slots }) {
    const searchStr = ref('');
    const showSelect = ref(false);

    const innerItems = computed<ListItem[]>(() => {
      if (!props.items) return [];
      const items = props.items.map((el: Option) => {
        return {
          ...el,
          forUuId: _.uniqueId(),
        };
      });
      if (props.selectAll) {
        items.unshift({
          [props.idValue]: null,
          [props.textValue]: 'Все',
          forUuId: _.uniqueId(),
        });
      }
      return items;
    });

    const innerValue = computed(() => {
      if (props.selectFirstItem) {
        return props.items[0];
      }
      return innerItems.value.find(el =>
        el[props.idValue] === (_.isObject(props.value)? props.value[props.idValue] : props.value)) || null;
    });

    const model = computed(() => {
      return innerValue.value && _.isObject(innerValue.value)
        ? innerValue.value[props.textValue]
        : null;
    });

    const modelId = computed(() => {
      return innerValue.value && _.isObject(innerValue.value)
        ? innerValue.value[props.idValue]
        : null;
    });

    const isSlotItem = computed(() => !!slots.item);
    const isSlotInput = computed(() => !!slots.input);

    const selectLabelIsActive = computed(() => props.value || showSelect.value);

    function searchByText(text: string) {
      emit('search-change', text);
    }

    function clearSelect(ev: Event) {
      emit('input', null);
      ev.stopPropagation();
    }

    function changeViewList() {
      showSelect.value = !props.disabled && !showSelect.value;
    }

    function selectItem(item: ListItem) {
      const val = this.returnObject ? item : item[this.idValue];
      emit('change', _.omit(item, "forUuId"));
      emit('input', val);
      showSelect.value = false;
    }

    return {
      searchStr,
      showSelect,
      searchByText,
      clearSelect,
      selectLabelIsActive,
      model,
      modelId,
      innerItems,
      isSlotItem,
      changeViewList,
      innerValue,
      isSlotInput,
      selectItem, 
    };
  },

});
