




























import VSelect from "@/app/components/Base/VSelect.vue";

type Option = Record<string, unknown>;

import { computed, PropType } from "@vue/composition-api";
import { defineComponent } from "@vue/composition-api/dist/vue-composition-api";

import IconThinArrowBottom from "@/app/components/Icons/IconThinArrowBottom.vue";

export default defineComponent(
  {
    name: "PanelDropdown",
    components: {
      VSelect,
      IconThinArrowBottom, 
    },
    props: {
      value: {
        type: String as PropType<string | null>,
        default: () => null,
      },
      items: {
        type: Array as PropType<Option[]>,
        default: () => [],
      },
      textValue: {
        type: String,
        default: () => 'value',
      },
      idValue: {
        type: String,
        default: () => 'id',
      },
      disabled: {
        type: Boolean,
        default: () => false,
      },
    },
    setup(props, { slots }) {
      const hasSlotItem = computed(() => !!slots.item);
      return { hasSlotItem };
    },
  },
);
