import { useQuery, useResult, UseResultReturn } from "@vue/apollo-composable";
import { OptionsParameter, UseQueryReturn } from "@vue/apollo-composable/dist/useQuery";

import { NonNullableField, Path, PathValue } from "@/shared/types";
import {
  GetCommentPageDocument,
  GetCommentPageQuery,
  GetCommentPageQueryVariables,
  GetInitialTaskStatusesListDocument,
  GetInitialTaskStatusesListQuery,
  GetProjectDocument,
  GetProjectPrioritiesListDocument,
  GetProjectPrioritiesListQuery,
  GetProjectQuery,
  GetProjectQueryVariables,
  GetProjectShortDocument,
  GetProjectShortQuery,
  GetProjectShortQueryVariables,
  GetProjectStatusesDocument,
  GetProjectStatusesQuery,
  GetProjectTasksDocument,
  GetProjectTasksQuery,
  GetProjectTasksQueryVariables,
  GetProjectTaskStatusesDocument,
  GetProjectTaskStatusesQuery,
  GetProjectTaskStatusesQueryVariables,
  GetTaskChangeLogDocument,
  GetTaskChangeLogQuery,
  GetTaskChangeLogQueryVariables,
  GetTaskCommentPageDocument,
  GetTaskCommentPageQuery,
  GetTaskCommentPageQueryVariables,
  GetTaskDocument,
  GetTaskPrioritiesDocument,
  GetTaskPrioritiesQuery,
  GetTaskQuery,
  GetTaskQueryVariables,
  GetTasksDocument,
  GetTasksQuery,
  GetTasksQueryVariables,
  GetTaskStatusesDocument,
  GetTaskStatusesQuery,
  GetUsersListDocument,
  GetUsersListQuery,
  GetUsersListQueryVariables,
} from "@/shared/types/schema.types";

interface Result<T, K extends Path<T>, V extends unknown> {
  data: UseResultReturn<NonNullableField<PathValue<T, K>>>;
  onResult: UseQueryReturn<T, V>["onResult"];
  onError: UseQueryReturn<T, V>["onError"];
  loading: UseQueryReturn<T, V>["loading"];
  refetch?: UseQueryReturn<T, V>["refetch"];
}

export function useProjectTasks(
  params: GetProjectTasksQueryVariables,
): Result<GetProjectTasksQuery, "project.tasks", GetProjectTasksQueryVariables> {
  const { result, onResult, onError, loading } = useQuery(GetProjectTasksDocument, params);
  const data = useResult(result, [], (data) => data.project.tasks);

  return {
    data,
    onResult,
    onError,
    loading,
  };
}

export function useProject(
  params: GetProjectQueryVariables,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
): Result<GetProjectQuery, "project", GetProjectQueryVariables> {
  const { result, onResult, loading, onError, refetch } = useQuery(GetProjectDocument, params);
  const data = useResult(result, null as never, (data) => data.project);

  return {
    data,
    onResult,
    onError,
    loading,
    refetch,
  };
}

export function useProjectShort(
  params: GetProjectShortQueryVariables,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
): Result<GetProjectShortQuery, "project", GetProjectShortQueryVariables> {
  const { result, onResult, loading, onError } = useQuery(GetProjectShortDocument, params, {
    fetchPolicy: "cache-and-network",
  });
  const data = useResult(result, null as never, (data) => data.project);

  return {
    data,
    onResult,
    onError,
    loading,
  };
}

export function usePriorities(): Result<GetProjectPrioritiesListQuery, "projectPriorities", null> {
  const { result, onResult, onError, loading } = useQuery(GetProjectPrioritiesListDocument, {});
  const data = useResult(result, [], (data) => data.projectPriorities);
  return {
    data,
    onResult,
    onError,
    loading,
  };
}

export function useProjectStatuses(): Result<GetProjectStatusesQuery, "projectStatuses", null> {
  const { result, onResult, onError, loading } = useQuery(
    GetProjectStatusesDocument,
    {},
    { fetchPolicy: "cache-first" },
  );
  const data = useResult(result, [], (data) => data.projectStatuses);
  return {
    data,
    onResult,
    onError,
    loading,
  };
}

export function useUsers(
  params: GetUsersListQueryVariables,
): Result<GetUsersListQuery, "users", GetUsersListQueryVariables> {
  const { result, onResult, onError, loading } = useQuery(GetUsersListDocument, params);
  const data = useResult(result, [], (data) => data.users);
  return {
    data,
    onResult,
    onError,
    loading,
  };
}

export function useTaskPriorities(): Result<GetTaskPrioritiesQuery, "taskPriorities", null> {
  const { result, onResult, onError, loading } = useQuery(GetTaskPrioritiesDocument);
  const data = useResult(result, [], (data) => data.taskPriorities);
  return {
    data,
    onResult,
    onError,
    loading,
  };
}

export function useTaskStatuses(): Result<GetTaskStatusesQuery, "taskStatuses", null> {
  const { result, onResult, onError, loading } = useQuery(GetTaskStatusesDocument);
  const data = useResult(result, [], (data) => data.taskStatuses);
  return {
    data,
    onResult,
    onError,
    loading,
  };
}

export function useProjectTaskStatuses(
  params: GetProjectTaskStatusesQueryVariables,
): Result<
  GetProjectTaskStatusesQuery,
  "project.taskStatuses",
  GetProjectTaskStatusesQueryVariables
> {
  const { result, onResult, onError, loading } = useQuery(GetProjectTaskStatusesDocument, params);
  const data = useResult(result, [], (data) => data.project.taskStatuses);
  return {
    data,
    onResult,
    onError,
    loading,
  };
}

export function useInitialTaskStatuses(): Result<
  GetInitialTaskStatusesListQuery,
  "initialTaskStatuses",
  null
> {
  const { result, onResult, onError, loading } = useQuery(GetInitialTaskStatusesListDocument);
  const data = useResult(result, [], (data) => data.initialTaskStatuses);
  return {
    data,
    onResult,
    onError,
    loading,
  };
}

export function useTasks(
  params: GetTasksQueryVariables,
): Result<GetTasksQuery, "tasks", GetTasksQueryVariables> {
  const { result, onResult, onError, loading } = useQuery(GetTasksDocument, params);
  const data = useResult(result, [], (data) => data.tasks);
  return {
    data,
    onResult,
    onError,
    loading,
  };
}

export function useTask(
  params: GetTaskQueryVariables,
  options?: OptionsParameter<
    Result<GetTaskQuery, "task", GetTaskQueryVariables>,
    GetTaskQueryVariables
  >,
): Result<GetTaskQuery, "task", GetTaskQueryVariables> {
  const { result, onResult, loading, onError, refetch } = useQuery(
    GetTaskDocument,
    params,
    options || {},
  );
  const data = useResult(result, null as never, (data) => data.task);
  return {
    data,
    onResult,
    onError,
    loading,
    refetch,
  };
}

export function useTaskComments(
  params: GetTaskCommentPageQueryVariables,
  options?: OptionsParameter<
    Result<GetTaskCommentPageQuery, "task.commentPage", GetTaskCommentPageQueryVariables>,
    GetTaskCommentPageQueryVariables
  >,
): Result<GetTaskCommentPageQuery, "task.commentPage", GetTaskCommentPageQueryVariables> {
  const { result, onResult, loading, onError, refetch } = useQuery(
    GetTaskCommentPageDocument,
    params,
    options || {},
  );
  const data = useResult(result, null as never, (data) => data.task.commentPage);
  return {
    data,
    onResult,
    onError,
    loading,
    refetch,
  };
}

export function useCommentPage(
  params: GetCommentPageQueryVariables,
  options?: OptionsParameter<
    Result<GetCommentPageQuery, "commentPage", GetCommentPageQueryVariables>,
    GetCommentPageQueryVariables
  >,
): Result<GetCommentPageQuery, "commentPage", GetCommentPageQueryVariables> {
  const { result, onResult, loading, onError, refetch } = useQuery(
    GetCommentPageDocument,
    params,
    options || {},
  );
  const data = useResult(result, null as never, (data) => data.commentPage);
  return {
    data,
    onResult,
    onError,
    loading,
    refetch,
  };
}

export function useTaskChangeLog(
  params: GetTaskChangeLogQueryVariables,
): Result<GetTaskChangeLogQuery, "task.changeLog", GetTaskChangeLogQueryVariables> {
  const { result, onResult, onError, loading } = useQuery(GetTaskChangeLogDocument, params);
  const data = useResult(result, null as never, (data) => data.task.changeLog);
  return {
    data,
    onResult,
    onError,
    loading,
  };
}
