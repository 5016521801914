import useRoot from "@/shared/hooks/root/useRoot";

export function useNotification() {
  const { injectRoot } = useRoot();
  const root = injectRoot();

  function getErrorText(err: unknown): string {
    return _.get(err, "response.data.message") || _.get(err, "message") || err || "Ошибка";
  }

  const errNotification = (msg: unknown) => {
    if (!root) return;
    root.$notification({
      text: getErrorText(msg),
      icon: "remove",
      type: "error",
    });
  };

  const successNotification = (msg: string) => {
    if (!root) return;
    root.$notification({
      text: msg,
      icon: "remove",
      type: "success",
    });
  };

  return {
    errNotification,
    successNotification,
  };
}
