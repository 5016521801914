















import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "IconClose",
  props: {
    width: {
      type: Number as PropType<number>,
      default: () => 32,
    },
    height: {
      type: Number as PropType<number>,
      default: () => 32,
    },
    fill: {
      type: String as PropType<string>,
      default: () => "#3D75E4",
    },
  },
});
